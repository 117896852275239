import {getCurrentYear} from '../../../utils';
import {CONTACT_DATA} from '../constants';
import {hubURLs, staticURLs, URLs} from '../../api-urls';
import {terms_and_conditions_en} from '../terms-and-conditions';
import {challenges_en} from '../home-page/challenges';
import {advantages_en} from '../home-page/advantages';
import {about_marketplace_en} from '../about-marketplace';
import {about_independent_technical_surveying_en} from '../about-independent-technical-surveying';
import {about_core_services_en} from '../about-core-services';
import BreadcrumbsConstructor from '../../breadcrumbs';

const en = (session) => ({
    meta_tags: {
        title: 'Exportery',
        description: 'Truly industrial e-marketplace',
    },

    home_page: {
        banner_text: ['Truly industrial', 'e-trade platform'],
        image_alt: 'Banner image',
        main_title: `We bridge the cross-border gap between OEM manufacturers of global 
        spare parts and the end users within the aviation and land transport industries`,
        advantages_content: advantages_en,
        challenges_subtitle: 'Challenges',
        challenges_text: `The end-users, Importers, MROs, air operators, Government end-users, 
        and Exporters, including OEMs and overhaul plants, encounter "the problem" in the spare 
        parts and components supply chain.`,
        challenges_list: challenges_en,
        video_content: {
            title: ['Welcome to ', 'the new world of ', 'cross-border business'],
        },
        red_zone_title: 'Request access now',
        red_zone_text: `Access to our e-trade platform Exportery is FREE for Exporters 
        and Importers. You can assess the functionality of the system and its content 
        by providing a few details by clicking on the button below.`,
        button: {
            request_access_btn: 'Request access',
            play_video: 'Play video',
            public_marketplace_btn: 'Try now',
        },
    },

    about_marketplace: about_marketplace_en,

    about_independent_technical_surveying: about_independent_technical_surveying_en,

    about_core_services: about_core_services_en,

    news: {
        title: 'Market Research News, Articles & Analysis',
        page: URLs.news,
        no_articles: 'No articles to display',
        image_alt: 'Article image',
        button: {
            view: 'Read more',
            all_news: 'Go to news',
        },
    },

    section: {
        news: {
            title: 'Market Research News, Articles & Analysis',
            page: URLs.news,
            no_articles: 'No articles to display',
        },
        shop_by_brand: {
            title: 'Source spare parts and components designed by:',
        },
        no_content: 'No items to display',
        button: {
            more_btn: 'show more',
            less_btn: 'show less',
        },
    },

    terms_and_conditions: {
        nav_menu: 'Chapter navigation',
        data: terms_and_conditions_en,
    },

    privacy_policy: {
        title: 'Privacy policy',
        updated: 'Last updated: June 21, 2022',
        text: [
            [`This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of 
                Your information when You use the Service and tells You about Your privacy rights and how the law
                protects You.`],
            [`We use Your Personal data to provide and improve the Service. By using the Service, You agree to the 
                collection and use of information in accordance with this Privacy Policy.`],
            {tag: 'h1', text: 'Interpretation and Definitions'},
            {tag: 'h2', text: 'Interpretation'},
            [`The words of which the initial letter is capitalized have meanings defined under the following
                conditions. The following definitions shall have the same meaning regardless of whether they appear
                in singular or in plural.`],
            {tag: 'h2', text: 'Definitions'},
            [`For the purposes of this Privacy Policy:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Account '},
                        {
                            tag: 'span',
                            text: 'means a unique account created for You to access our Service or parts of our Service.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Business'},
                        {
                            tag: 'span',
                            text: `, for the purpose of the CCPA (California Consumer Privacy Act), 
                            refers to the Company as the legal entity that collects Consumers' personal information and
                            determines the purposes and means of the processing of Consumers' personal information, or
                            on behalf of which such information is collected and that alone, or jointly with others,
                            determines the purposes and means of the processing of consumers' personal information, that
                            does business in the State of California.`,
                        },
                    ],
                    [
                        [
                            {tag: 'strong', text: 'Company '},
                            {
                                tag: 'span',
                                text: `(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to ${CONTACT_DATA.address[0]} ${CONTACT_DATA.address[1]} ${CONTACT_DATA.address[2]}.`,
                            },
                        ],
                        ['For the purpose of the GDPR, the Company is the Data Controller.'],
                    ],
                    [
                        {tag: 'strong', text: 'Consumer'},
                        {
                            tag: 'span',
                            text: `, for the purpose of the CCPA (California Consumer Privacy Act),
                            means a natural person who is a California resident. A resident, as defined in the law,
                            includes (1) every individual who is in the USA for other than a temporary or transitory
                            purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a
                            temporary or transitory purpose.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Cookies '},
                        {
                            tag: 'span',
                            text: `are small files that are placed on Your computer, mobile device or
                            any other device by a website, containing the details of Your browsing history on that
                            website among its many uses.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Country '},
                        {tag: 'span', text: 'refers to: Georgia.'},
                    ],
                    [
                        {tag: 'strong', text: 'Data Controller'},
                        {
                            tag: 'span',
                            text: `, for the purposes of the GDPR (General Data Protection
                            Regulation), refers to the Company as the legal person which alone or jointly with others
                            determines the purposes and means of the processing of Personal Data.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Device '},
                        {
                            tag: 'span',
                            text: 'means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Do Not Track '},
                        {
                            tag: 'span',
                            text: `(DNT) is a concept that has been promoted by US regulatory
                            authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet
                            industry to develop and implement a mechanism for allowing internet users to control the
                            tracking of their online activities across websites.`,
                        },
                    ],
                    [
                        [
                            {tag: 'strong', text: 'Personal Data '},
                            {
                                tag: 'span',
                                text: 'is any information that relates to an identified or identifiable individual.',
                            },
                        ],
                        [`For the purposes of GDPR, Personal Data means any information relating to You such as a name,
                            an identification number, location data, online identifier or to one or more factors
                            specific to the physical, physiological, genetic, mental, economic, cultural or social
                            identity.`],
                        [`For the purposes of the CCPA, Personal Data means any information that identifies, relates
                            to, describes or is capable of being associated with, or could reasonably be linked,
                            directly or indirectly, with You.`],
                    ],
                    [
                        {tag: 'strong', text: 'Sale'},
                        {
                            tag: 'span',
                            text: `, for the purpose of the CCPA (California Consumer Privacy Act), means
                            selling, renting, releasing, disclosing, disseminating, making available, transferring, or
                            otherwise communicating orally, in writing, or by electronic or other means, a Consumer's
                            personal information to another business or a third party for monetary or other valuable
                            consideration.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Service '},
                        {tag: 'span', text: 'refers to the Website.'},
                    ],
                    [
                        {tag: 'strong', text: 'Service Provider '},
                        {
                            tag: 'span',
                            text: `means any natural or legal person who processes the data on
                            behalf of the Company. It refers to third-party companies or individuals employed by the
                            Company to facilitate the Service, to provide the Service on behalf of the Company, to
                            perform services related to the Service or to assist the Company in analyzing how the
                            Service is used.
                            For the purpose of the GDPR, Service Providers are considered Data Processors.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Usage Data '},
                        {
                            tag: 'span',
                            text: `refers to data collected automatically, either generated by the
                            use of the Service or from the Service infrastructure itself (for example, the duration of a
                            page visit).`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Website '},
                        {tag: 'span', text: 'refers to Exportery, accessible from '},
                        {
                            tag: 'a',
                            href: 'https://exportery.com',
                            rel: '',
                            target: '_blank',
                            text: 'exportery.com',
                        },
                        {tag: 'span', text: '.'},
                    ],
                    [
                        [
                            {tag: 'strong', text: 'You '},
                            {
                                tag: 'span',
                                text: `means the individual accessing or using the Service, or the company, or
                                other legal entity on behalf of which such individual is accessing or using the Service, as
                                applicable.`,
                            },
                        ],
                        [`Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject
                            or as the User as you are the individual using the Service.`],
                    ],
                ],
            },
            {tag: 'h1', text: 'Collecting and Using Your Personal Data'},
            {tag: 'h2', text: 'Types of Data Collected'},
            {tag: 'h3', text: 'Personal Data'},
            [`While using Our Service, We may ask You to provide Us with certain personally identifiable
                information that can be used to contact or identify You. Personally identifiable information may
                include, but is not limited to:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Email address.',
                    'First name and last name.',
                    'Phone number.',
                    'Address, State, Province, ZIP/Postal code, City.',
                    'Bank account information in order to pay for products and/or services within the Service.',
                    'Usage Data.',
                ],
            },
            [`When You pay for a product and/or a service via bank transfer, We may ask You to provide information
                to facilitate this transaction and to verify Your identity. Such information may include, without
                limitation:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Date of birth.',
                    'Passport or National ID card.',
                    'Bank card statement.',
                    'Other information linking You to an address.',
                ],
            },
            {tag: 'h3', text: 'Usage Data'},
            ['Usage Data is collected automatically when using the Service.'],
            [`Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
                browser type, browser version, the pages of our Service that You visit, the time and date of Your
                visit, the time spent on those pages, unique device identifiers and other diagnostic data.`],
            [`When You access the Service by or through a mobile device, We may collect certain information
                automatically, including, but not limited to, the type of mobile device You use, Your mobile device
                unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile
                Internet browser You use, unique device identifiers and other diagnostic data.`],
            [`We may also collect information that Your browser sends whenever You visit our Service or when You
                access the Service by or through a mobile device.`],
            {tag: 'h3', text: 'Tracking Technologies and Cookies'},
            [`We use Cookies and similar tracking technologies to track the activity on Our Service and store
                certain information. Tracking technologies used are beacons, tags, and scripts to collect and track
                information and to improve and analyze Our Service. The technologies We use may include:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Cookies or Browser Cookies. '},
                        {
                            tag: 'span',
                            text: `A cookie is a small file placed on Your Device. You
                            can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent.
                            However, if You do not accept Cookies, You may not be able to use some parts of our Service.
                            Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may
                            use Cookies.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Flash Cookies. '},
                        {
                            tag: 'span',
                            text: `Certain features of our Service may use local stored objects (or
                            Flash Cookies) to collect and store information about Your preferences or Your activity on our
                            Service. Flash Cookies are not managed by the same browser settings as those used for Browser
                            Cookies. For more information on how You can delete Flash Cookies, please read "Where can I
                            change the settings for disabling, or deleting local shared objects?" available at `,
                        },
                        {
                            tag: 'a',
                            href: 'https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_',
                            rel: 'external nofollow noopener noreferrer',
                            target: '_blank',
                            text: 'https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_',
                        },
                        {tag: 'span', text: '.'},
                    ],
                    [
                        {tag: 'strong', text: 'Web Beacons. '},
                        {
                            tag: 'span',
                            text: `Certain sections of our Service and our emails may contain small
                            electronic files known as web beacons (also referred to as clear gifs, pixel tags, and
                            single-pixel gifs) that permit the Company, for example, to count users who have visited those
                            pages or opened an email and for other related website statistics (for example, recording the
                            popularity of a certain section and verifying system and server integrity).`,
                        },
                    ],
                ],
            },
            [
                {
                    tag: 'span',
                    text: `Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on
                    Your personal computer or mobile device when You go offline, while Session Cookies are deleted as
                    soon as You close Your web browser. Learn more about cookies on the `,
                },
                {
                    tag: 'a',
                    href: 'https://www.privacypolicies.com/blog/privacy-policy-template/#Use_Of_Cookies_Log_Files_And_Tracking',
                    rel: 'external nofollow noopener noreferrer',
                    target: '_blank',
                    text: 'Privacy Policies website',
                },
                {tag: 'span', text: ' article.'},
            ],
            ['We use both Session and Persistent Cookies for the purposes set out below:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Necessary / Essential Cookies.'},
                        ['Type: Session Cookies.'],
                        ['Administered by: Us.'],
                        [`Purpose: These Cookies are essential to provide You with services available through the
                            Website and to enable You to use some of its features. They help to authenticate users and
                            prevent fraudulent use of user accounts. Without these Cookies, the services that You have
                            asked for cannot be provided, and We only use these Cookies to provide You with those
                            services.`],
                    ],
                    [
                        {tag: 'strong', text: 'Cookies Policy / Notice Acceptance Cookies.'},
                        ['Type: Persistent Cookies.'],
                        ['Administered by: Us.'],
                        ['Purpose: These Cookies identify if users have accepted the use of cookies on the Website.'],
                    ],
                    [
                        {tag: 'strong', text: 'Functionality Cookies.'},
                        ['Type: Persistent Cookies.'],
                        ['Administered by: Us.'],
                        [`Purpose: These Cookies allow us to remember choices You make when You use the Website, such
                            as remembering your login details or language preference. The purpose of these Cookies is to
                            provide You with a more personal experience and to avoid You having to re-enter your
                            preferences every time You use the Website.`],
                    ],
                    [
                        {tag: 'strong', text: 'Targeting and Advertising Cookies.'},
                        ['Type: Persistent Cookies.'],
                        ['Administered by: Third-Parties.'],
                        [`Purpose: These Cookies track your browsing habits to enable Us to show advertising which is
                            more likely to be of interest to You. These Cookies use information about your browsing
                            history to group You with other users who have similar interests. Based on that information,
                            and with Our permission, third party advertisers can place Cookies to enable them to show
                            adverts which We think will be relevant to your interests while You are on third party
                            websites.`],
                    ],
                ],
            },
            [`For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.`],
            {tag: 'h2', text: 'Use of Your Personal Data'},
            ['The Company may use Personal Data for the following purposes:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'To provide and maintain our Service'},
                        {tag: 'span', text: ', including to monitor the usage of our Service.'},
                    ],
                    [
                        {tag: 'strong', text: 'To manage Your Account: '},
                        {
                            tag: 'span',
                            text: `to manage Your registration as a user of the
                            Service. The Personal Data You provide can give You access to different functionalities of
                            the Service that are available to You as a registered user.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'For the performance of a contract: '},
                        {
                            tag: 'span',
                            text: `the development, compliance and
                            undertaking of the purchase contract for the products, items or services You have purchased
                            or of any other contract with Us through the Service.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'To contact You. '},
                        {
                            tag: 'span',
                            text: `To contact You by email, telephone calls, SMS, or other
                            equivalent forms of electronic communication, such as a mobile application's push
                            notifications regarding updates or informative communications related to the
                            functionalities, products or contracted services, including the security updates, when
                            necessary or reasonable for their implementation.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'To provide You '},
                        {
                            tag: 'span',
                            text: `with news, special offers and general information about other
                            goods, services and events which we offer that are similar to those that you have already
                            purchased or enquired about unless You have opted not to receive such information.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'To manage Your requests. '},
                        {
                            tag: 'span',
                            text: 'To attend and manage Your requests to Us.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'For business transfers. '},
                        {
                            tag: 'span',
                            text: `We may use Your information to evaluate or conduct a
                            merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer
                            of some or all of Our assets, whether as a going concern or as part of bankruptcy,
                            liquidation, or similar proceeding, in which Personal Data held by Us about our Service
                            users is among the assets transferred.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'For other purposes. '},
                        {
                            tag: 'span',
                            text: `We may use Your information for other purposes, such as
                            data analysis, identifying usage trends, determining the effectiveness of our promotional
                            campaigns and to evaluate and improve our Service, products, services, marketing and your
                            experience.`,
                        },
                    ],
                ],
            },
            ['We may share Your personal information in the following situations:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'With Service Providers. '},
                        {
                            tag: 'span',
                            text: `We may share Your personal information with Service
                            Providers to monitor and analyze the use of our Service, to show advertisements to You to help
                            support and maintain Our Service, for payment processing, to contact You.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'For business transfers. '},
                        {
                            tag: 'span',
                            text: `We may share or transfer Your personal information in
                            connection with, or during negotiations of, any merger, sale of Company assets, financing, or
                            acquisition of all or a portion of Our business to another company.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'With Affiliates. '},
                        {
                            tag: 'span',
                            text: `We may share Your information with Our affiliates, in which
                            case we will require those affiliates to honor this Privacy Policy. Affiliates include Our
                            parent company and any other subsidiaries, joint venture partners or other companies that We
                            control or that are under common control with Us.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'With business partners. '},
                        {
                            tag: 'span',
                            text: 'We may share Your information with Our business partners to offer You certain products, services or promotions.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'With other users: '},
                        {
                            tag: 'span',
                            text: `when You share personal information or otherwise interact in
                            the public areas with other users, such information may be viewed by all users and may be
                            publicly distributed outside.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'With Your consent. '},
                        {
                            tag: 'span',
                            text: 'We may disclose Your personal information for any other purpose with Your consent.',
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Retention of Your Personal Data'},
            [`The Company will retain Your Personal Data only for as long as is necessary for the purposes set out
                in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply
                with our legal obligations (for example, if we are required to retain your data to comply with
                applicable laws), resolve disputes, and enforce our legal agreements and policies.`],
            [`The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally
                retained for a shorter period of time, except when this data is used to strengthen the security or
                to improve the functionality of Our Service, or We are legally obligated to retain this data for
                longer time periods.`],
            {tag: 'h2', text: 'Transfer of Your Personal Data'},
            [`Your information, including Personal Data, is processed at the Company's operating offices and in any
                other places where the parties involved in the processing are located. It means that this
                information may be transferred to — and maintained on — computers located outside of Your state,
                province, country or other governmental jurisdiction where the data protection laws may differ than
                those from Your jurisdiction.`],
            [`Your consent to this Privacy Policy followed by Your submission of such information represents Your
                agreement to that transfer.`],
            [`The Company will take all steps reasonably necessary to ensure that Your data is treated securely and
                in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an
                organization or a country unless there are adequate controls in place including the security of Your
                data and other personal information.`],
            {tag: 'h2', text: 'Disclosure of Your Personal Data'},
            {tag: 'h3', text: 'Business Transactions'},
            [`If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
                transferred. We will provide notice before Your Personal Data is transferred and becomes subject to
                a different Privacy Policy.`],
            {tag: 'h3', text: 'Law enforcement'},
            [`Under certain circumstances, the Company may be required to disclose Your Personal Data if required
                to do so by law or in response to valid requests by public authorities (e.g. a court or a government
                agency).`],
            {tag: 'h3', text: 'Other legal requirements'},
            ['The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Comply with a legal obligation.',
                    'Protect and defend the rights or property of the Company.',
                    'Prevent or investigate possible wrongdoing in connection with the Service.',
                    'Protect the personal safety of Users of the Service or the public.',
                    'Protect against legal liability.',
                ],
            },
            {tag: 'h2', text: 'Security of Your Personal Data'},
            [`The security of Your Personal Data is important to Us, but remember that no method of transmission
                over the Internet, or method of electronic storage is 100% secure. While We strive to use
                commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute
                security.`],
            {tag: 'h1', text: 'Detailed Information on the Processing of Your Personal Data'},
            [`The Service Providers We use may have access to Your Personal Data. These third-party vendors
                collect, store, use, process and transfer information about Your activity on Our Service in
                accordance with their Privacy Policies.`],
            {tag: 'h2', text: 'Advertising'},
            ['We may use Service Providers to show advertisements to You to help support and maintain Our Service.'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Google AdSense & DoubleClick Cookie.'},
                        [`Google, as a third party vendor, uses cookies to serve ads on our Service. Google's use of
                            the DoubleClick cookie enables it and its partners to serve ads to our users based on their
                            visit to our Service or other websites on the Internet.`],
                        [
                            {
                                tag: 'span',
                                text: `You may opt out of the use of the DoubleClick Cookie for interest-based advertising by
                                visiting the Google Ads Settings web page: `,
                            },
                            {
                                tag: 'a',
                                href: 'http://www.google.com/ads/preferences/',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'http://www.google.com/ads/preferences/',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                    [
                        {tag: 'strong', text: 'AdMob by Google.'},
                        ['AdMob by Google is provided by Google Inc.'],
                        [
                            {
                                tag: 'span',
                                text: 'You can opt-out from the AdMob by Google service by following the instructions described by Google: ',
                            },
                            {
                                tag: 'a',
                                href: 'https://support.google.com/ads/answer/2662922?hl=en',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://support.google.com/ads/answer/2662922?hl=en',
                            },
                            {tag: 'span', text: '.'},
                        ],
                        [
                            [`For more information on how Google uses the collected information, please visit the "How
                                Google uses data when you use our partners' sites or app" page: `],
                            {
                                tag: 'a',
                                href: 'https://policies.google.com/technologies/partner-sites',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://policies.google.com/technologies/partner-sites',
                            },
                            {tag: 'span', text: ' or visit the Privacy Policy of Google: '},
                            {
                                tag: 'a',
                                href: 'https://policies.google.com/privacy',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://policies.google.com/privacy',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                ],
            },
            {tag: 'h2', text: 'Email Marketing'},
            [`We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and
                other information that may be of interest to You. You may opt-out of receiving any, or all, of these
                communications from Us by following the unsubscribe link or instructions provided in any email We
                send or by contacting Us.`],
            ['We may use Email Marketing Service Providers to manage and send emails to You.'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Postmark'},
                        [
                            {
                                tag: 'span',
                                text: 'Their Privacy Policy can be viewed at ',
                            },
                            {
                                tag: 'a',
                                href: 'https://postmarkapp.com/',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://postmarkapp.com/',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                ],
            },
            {tag: 'h2', text: 'Payments'},
            [`We may provide paid products and/or services within the Service. In that case, we may use third-party
                services for payment processing (e.g. payment processors).`],
            [`We will not store or collect Your payment card details. That information is provided directly to Our
                third-party payment processors whose use of Your personal information is governed by their Privacy
                Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI
                Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American
                Express and Discover. PCI-DSS requirements help ensure the secure handling of payment
                information.`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Bilderlings'},
                        [
                            {
                                tag: 'span',
                                text: 'Their Privacy Policy can be viewed at ',
                            },
                            {
                                tag: 'a',
                                href: 'https://bilderlings.com',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://bilderlings.com',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                ],
            },
            [`When You use Our Service to pay a product and/or service via bank transfer, We may ask You to provide
                information to facilitate this transaction and to verify Your identity.`],
            {tag: 'h2', text: 'Usage, Performance and Miscellaneous'},
            ['We may use third-party Service Providers to provide better improvement of our Service.'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Invisible reCAPTCHA'},
                        ['We use an invisible captcha service named reCAPTCHA. reCAPTCHA is operated by Google.'],
                        ['The reCAPTCHA service may collect information from You and from Your Device for security purposes.'],
                        [
                            {
                                tag: 'span',
                                text: 'The information gathered by reCAPTCHA is held in accordance with the Privacy Policy of Google: ',
                            },
                            {
                                tag: 'a',
                                href: 'https://www.google.com/intl/en/policies/privacy/',
                                rel: 'external nofollow noopener noreferrer',
                                target: '_blank',
                                text: 'https://www.google.com/intl/en/policies/privacy/',
                            },
                            {tag: 'span', text: '.'},
                        ],
                    ],
                ],
            },
            {tag: 'h1', text: 'GDPR Privacy'},
            {tag: 'h2', text: 'We may process Personal Data under the following conditions:'},
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Consent. '},
                        {
                            tag: 'span',
                            text: 'You have given Your consent for processing Personal Data for one or more specific purposes.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Performance of a contract. '},
                        {
                            tag: 'span',
                            text: 'Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Legal obligations. '},
                        {
                            tag: 'span',
                            text: 'Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Vital interests. '},
                        {
                            tag: 'span',
                            text: 'Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Public interests. '},
                        {
                            tag: 'span',
                            text: 'Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Legitimate interests. '},
                        {
                            tag: 'span',
                            text: 'Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.',
                        },
                    ],
                ],
            },
            [`In any case, the Company will gladly help to clarify the specific legal basis that applies to the
                processing, and in particular whether the provision of Personal Data is a statutory or contractual
                requirement, or a requirement necessary to enter into a contract.`],
            {tag: 'h2', text: 'Your Rights under the GDPR'},
            ['The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.'],
            ['You have the right under this Privacy Policy, and by law if You are within the EU, to:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Request access to Your Personal Data. '},
                        {
                            tag: 'span',
                            text: `The right to access, update or delete the
                            information We have on You. Whenever made possible, you can access, update or request deletion
                            of Your Personal Data directly within Your account settings section. If you are unable to
                            perform these actions yourself, please contact Us to assist You. This also enables You to
                            receive a copy of the Personal Data We hold about You.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Request correction of the Personal Data that We hold about You. '},
                        {
                            tag: 'span',
                            text: 'You have the right to have any incomplete or inaccurate information We hold about You corrected.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Object to processing of Your Personal Data. '},
                        {
                            tag: 'span',
                            text: `This right exists where We are
                            relying on a legitimate interest as the legal basis for Our processing and there is something
                            about Your particular situation, which makes You want to object to our processing of Your
                            Personal Data on this ground. You also have the right to object where We are processing Your
                            Personal Data for direct marketing purposes.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Request erasure of Your Personal Data. '},
                        {
                            tag: 'span',
                            text: 'You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Request the transfer of Your Personal Data. '},
                        {
                            tag: 'span',
                            text: `We will provide to You, or to a
                            third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable
                            format. Please note that this right only applies to automated information which You initially
                            provided consent for Us to use or where We used the information to perform a contract with You.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Withdraw Your consent. '},
                        {
                            tag: 'span',
                            text: `You have the right to withdraw Your consent on using
                            your Personal Data. If You withdraw Your consent, We may not be able to provide You with access
                            to certain specific functionalities of the Service.`,
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Exercising of Your GDPR Data Protection Rights'},
            [`You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us.
                Please note that we may ask You to verify Your identity before responding to such requests. If You
                make a request, We will try our best to respond to You as soon as possible.`],
            [`You have the right to complain to a Data Protection Authority about Our collection and use of Your
                Personal Data. For more information, if You are in the European Economic Area (EEA), please contact
                Your local data protection authority in the EEA.`],
            {tag: 'h1', text: 'CCPA Privacy'},
            [`This privacy notice section for California residents supplements the information contained in Our
                Privacy Policy and it applies solely to all visitors, users, and others who reside in the State of
                California.`],
            {tag: 'h2', text: 'Categories of Personal Information Collected'},
            [`We collect information that identifies, relates to, describes, references, is capable of being
                associated with, or could reasonably be linked, directly or indirectly, with a particular Consumer
                or Device. The following is a list of categories of personal information which we may collect or may
                have been collected from California residents within the last twelve (12) months.`],
            [`Please note that the categories and examples provided in the list below are those defined in the
                CCPA. This does not mean that all examples of that category of personal information were in fact
                collected by Us, but reflects our good faith belief to the best of our knowledge that some of that
                information from the applicable category may be and may have been collected. For example, certain
                categories of personal information would only be collected if You provided such personal information
                directly to Us.`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Category A: Identifiers.'},
                        [`Examples: A real name, alias, postal address, unique personal identifier, online identifier,
                        Internet Protocol address, email address, account name, driver's license number, passport
                        number, or other similar identifiers.`],
                        ['Collected: Yes.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).',
                        },
                        [`Examples: A name, signature, Social Security number, physical characteristics or description,
                        address, telephone number, passport number, driver's license or state identification card
                        number, insurance policy number, education, employment, employment history, bank account
                        number, credit card number, debit card number, or any other financial information, medical
                        information, or health insurance information. Some personal information included in this
                        category may overlap with other categories.`],
                        ['Collected: Yes.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category C: Protected classification characteristics under California or federal law.',
                        },
                        [`Examples: Age (40 years or older), race, color, ancestry, national origin, citizenship,
                        religion or creed, marital status, medical condition, physical or mental disability, sex
                        (including gender, gender identity, gender expression, pregnancy or childbirth and related
                        medical conditions), sexual orientation, veteran or military status, genetic information
                        (including familial genetic information).`],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category D: Commercial information.',
                        },
                        ['Examples: Records and history of products or services purchased or considered.'],
                        ['Collected: Yes.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category E: Biometric information.',
                        },
                        [`Examples: Genetic, physiological, behavioral, and biological characteristics, or activity
                        patterns used to extract a template or other identifier or identifying information, such as,
                        fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other
                        physical patterns, and sleep, health, or exercise data.`],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category F: Internet or other similar network activity.',
                        },
                        ['Examples: Interaction with our Service or advertisement.'],
                        ['Collected: Yes.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category G: Geolocation data.',
                        },
                        ['Examples: Approximate physical location.'],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category H: Sensory data.',
                        },
                        ['Examples: Audio, electronic, visual, thermal, olfactory, or similar information.'],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category I: Professional or employment-related information.',
                        },
                        ['Examples: Current or past job history or performance evaluations.'],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category J: Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).',
                        },
                        [`Examples: Education records directly related to a student maintained by an educational
                        institution or party acting on its behalf, such as grades, transcripts, class lists, student
                        schedules, student identification codes, student financial information, or student
                        disciplinary records.`],
                        ['Collected: No.'],
                    ],
                    [
                        {
                            tag: 'strong',
                            text: 'Category K: Inferences drawn from other personal information.',
                        },
                        [`Examples: Profile reflecting a person's preferences, characteristics, psychological trends,
                        predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.`],
                        ['Collected: No.'],
                    ],
                ],
            },
            ['Under CCPA, personal information does not include:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Publicly available information from government records.',
                    'Deidentified or aggregated consumer information.',
                    [
                        {tag: 'span', text: 'Information excluded from the CCPA\'s scope, such as:'},
                        {
                            tag: 'ul',
                            className: 'second-nesting',
                            items: [
                                `Health or medical information covered by the Health Insurance Portability and
                                Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical
                                Information Act (CMIA) or clinical trial data.`,
                                `Personal Information covered by certain sector-specific privacy laws, including the Fair
                                Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial
                                Information Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.`,
                            ],
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Sources of Personal Information'},
            ['We obtain the categories of personal information listed above from the following categories of sources:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'Directly from You. '},
                        {
                            tag: 'span',
                            text: `For example, from the forms You complete on our Service,
                            preferences You express or provide through our Service, or from Your purchases on our Service.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Indirectly from You. '},
                        {
                            tag: 'span',
                            text: 'For example, from observing Your activity on our Service.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'Automatically from You. '},
                        {
                            tag: 'span',
                            text: 'For example, through cookies We or our Service Providers set on Your Device as You navigate through our Service.',
                        },
                    ],
                    [
                        {tag: 'strong', text: 'From Service Providers. '},
                        {
                            tag: 'span',
                            text: `For example, third-party vendors to provide advertising
                            on our Service, third-party vendors for payment processing, or other third-party vendors that We
                            use to provide the Service to You.`,
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Use of Personal Information for Business Purposes or Commercial Purposes'},
            [`We may use or disclose personal information We collect for "business
            purposes" or "commercial purposes" (as defined under the CCPA), which may include the
            following examples:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'To operate our Service and provide You with our Service.',
                    `To provide You with support and to respond to Your inquiries, including to investigate and
                    address Your concerns and monitor and improve our Service.`,
                    `To fulfill or meet the reason You provided the information. For example, if You share Your
                    contact information to ask a question about our Service, We will use that personal information
                    to respond to Your inquiry. If You provide Your personal information to purchase a product or
                    service, We will use that information to process Your payment and facilitate delivery.`,
                    `To respond to law enforcement requests and as required by applicable law, court order, or
                    governmental regulations.`,
                    `As described to You when collecting Your personal information or as otherwise set forth in the
                    CCPA.`,
                    'For internal administrative and auditing purposes.',
                    `To detect security incidents and protect against malicious, deceptive, fraudulent or illegal
                    activity, including, when necessary, to prosecute those responsible for such activities.`,
                ],
            },
            [`Please note that the examples provided above are illustrative and not intended to be exhaustive. For
            more details on how we use this information, please refer to the "Use of Your Personal
            Data" section.`],
            [`If We decide to collect additional categories of personal information or use the personal information
            We collected for materially different, unrelated, or incompatible purposes We will update this
            Privacy Policy.`],
            {tag: 'h2', text: 'Disclosure of Personal Information for Business Purposes or Commercial Purposes'},
            [`We may use or disclose and may have used or disclosed in the last twelve (12) months the following
            categories of personal information for business or commercial purposes:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Category A: Identifiers.',
                    `Category B: Personal information categories listed in the California Customer Records statute
                    (Cal. Civ. Code § 1798.80(e)).`,
                    'Category D: Commercial information.',
                    'Category F: Internet or other similar network activity.',
                ],
            },
            [`Please note that the categories listed above are those defined in the CCPA. This does not mean that
            all examples of that category of personal information were in fact disclosed, but reflects our good
            faith belief to the best of our knowledge that some of that information from the applicable category
            may be and may have been disclosed.`],
            [`When We disclose personal information for a business purpose or a commercial purpose, We enter a
            contract that describes the purpose and requires the recipient to both keep that personal
            information confidential and not use it for any purpose except performing the contract.`],
            {tag: 'h2', text: 'Sale of Personal Information'},
            [`As defined in the CCPA, "sell" and "sale" mean selling, renting, releasing,
            disclosing, disseminating, making available, transferring, or otherwise communicating orally, in
            writing, or by electronic or other means, a consumer's personal information by the business to a
            third party for valuable consideration. This means that We may have received some kind of benefit in
            return for sharing personal information, but not necessarily a monetary benefit.`],
            [`Please note that the categories listed below are those defined in the CCPA. This does not mean that
            all examples of that category of personal information were in fact sold, but reflects our good faith
            belief to the best of our knowledge that some of that information from the applicable category may
            be and may have been shared for value in return.`],
            ['We may sell and may have sold in the last twelve (12) months the following categories of personal information:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Category A: Identifiers.',
                    'Category B: Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).',
                    'Category D: Commercial information.',
                    'Category F: Internet or other similar network activity.',
                ],
            },
            {tag: 'h2', text: 'Share of Personal Information'},
            ['We may share Your personal information identified in the above categories with the following categories of third parties:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Service Providers.',
                    'Payment processors.',
                    'Our affiliates.',
                    'Our business partners.',
                    'Third party vendors to whom You or Your agents authorize Us to disclose Your personal information in connection with products or services We provide to You.',
                ],
            },
            {tag: 'h2', text: 'Sale of Personal Information of Minors Under 16 Years of Age'},
            [`We do not knowingly collect personal information from minors under the age of 16 through our Service,
                although certain third party websites that we link to may do so. These third-party websites have
                their own terms of use and privacy policies and we encourage parents and legal guardians to monitor
                their children's Internet usage and instruct their children to never provide information on other
                websites without their permission.`],
            [`We do not sell the personal information of Consumers We actually know are less than 16 years of age,
                unless We receive affirmative authorization (the "right to opt-in") from either the
                Consumer who is between 13 and 16 years of age, or the parent or guardian of a Consumer less than 13
                years of age. Consumers who opt-in to the sale of personal information may opt-out of future sales
                at any time. To exercise the right to opt-out, You (or Your authorized representative) may submit a
                request to Us by contacting Us.`],
            [`If You have reason to believe that a child under the age of 13 (or 16) has provided Us with personal
                information, please contact Us with sufficient detail to enable Us to delete that information.`],
            {tag: 'h2', text: 'Your Rights under the CCPA'},
            [`The CCPA provides California residents with specific rights regarding their personal information. If
                You are a resident of California, You have the following rights:`],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    [
                        {tag: 'strong', text: 'The right to notice. '},
                        {
                            tag: 'span',
                            text: `You have the right to be notified which categories of
                            Personal Data are being collected and the purposes for which the Personal Data is being used.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'The right to request. '},
                        {
                            tag: 'span',
                            text: `Under CCPA, You have the right to request that We
                            disclose information to You about Our collection, use, sale, disclosure for business purposes
                            and share of personal information. Once We receive and confirm Your request, We will disclose to
                            You:`,
                        },
                        {
                            tag: 'ul', className: 'second-nesting', items: [
                                'The categories of personal information We collected about You.',
                                'The categories of sources for the personal information We collected about You.',
                                'Our business or commercial purpose for collecting or selling that personal information.',
                                'The categories of third parties with whom We share that personal information.',
                                'The specific pieces of personal information We collected about You.',
                                [
                                    {
                                        tag: 'span',
                                        text: 'If we sold Your personal information or disclosed Your personal information for a business purpose, We will disclose to You:',
                                    },
                                    {
                                        tag: 'ul', className: 'third-nesting', items: [
                                            'The categories of personal information categories sold.',
                                            'The categories of personal information categories disclosed.',
                                        ],
                                    },
                                ],
                            ],
                        },
                    ],
                    [
                        {tag: 'strong', text: 'The right to say no to the sale of Personal Data (opt-out). '},
                        {
                            tag: 'span',
                            text: `You have the right to direct Us to not sell Your personal information. 
                            To submit an opt-out request please contact Us.`,
                        },
                    ],
                    [
                        {tag: 'strong', text: 'The right to delete Personal Data. '},
                        {
                            tag: 'span',
                            text: `You have the right to request the deletion
                            of Your Personal Data, subject to certain exceptions. Once We receive and confirm Your request,
                            We will delete (and direct Our Service Providers to delete) Your personal information from our
                            records, unless an exception applies. We may deny Your deletion request if retaining the
                            information is necessary for Us or Our Service Providers to:`,
                        },
                        {
                            tag: 'ul', className: 'second-nesting', items: [
                                `Complete the transaction for which We collected the personal information, provide a good
                                or service that You requested, take actions reasonably anticipated within the context of
                                our ongoing business relationship with You, or otherwise perform our contract with You.`,
                                `Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal
                                activity, or prosecute those responsible for such activities.`,
                                `Debug products to identify and repair errors that impair existing intended
                                functionality.`,
                                `Exercise free speech, ensure the right of another consumer to exercise their free speech
                                rights, or exercise another right provided for by law.`,
                                `Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546
                                et. seq.).`,
                                `Engage in public or peer-reviewed scientific, historical, or statistical research in the
                                public interest that adheres to all other applicable ethics and privacy laws, when the
                                information's deletion may likely render impossible or seriously impair the research's
                                achievement, if You previously provided informed consent.`,
                                `Enable solely internal uses that are reasonably aligned with consumer expectations based
                                on Your relationship with Us.`,
                                'Comply with a legal obligation.',
                                `Make other internal and lawful uses of that information that are compatible with the
                                context in which You provided it.`,
                            ],
                        },
                    ],
                    [
                        {tag: 'strong', text: 'The right not to be discriminated against. '},
                        {
                            tag: 'span',
                            text: `You have the right not to be discriminated against for exercising any of Your consumer's rights, including by:`,
                        },
                        {
                            tag: 'ul', className: 'second-nesting', items: [
                                'Denying goods or services to You.',
                                `Charging different prices or rates for goods or services, including the use of discounts
                                or other benefits or imposing penalties.`,
                                'Providing a different level or quality of goods or services to You.',
                                'Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services.',
                            ],
                        },
                    ],
                ],
            },
            {tag: 'h2', text: 'Exercising Your CCPA Data Protection Rights'},
            ['In order to exercise any of Your rights under the CCPA, and if You are a California resident, You can contact Us:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    `By email: ${CONTACT_DATA.email}.`,
                    [
                        {tag: 'span', text: 'By visiting this page on our website: '},
                        {
                            tag: 'a',
                            href: 'https://exportery.com',
                            rel: '',
                            target: '_blank',
                            text: 'https://exportery.com',
                        },
                        {tag: 'span', text: '.'},
                    ],
                    `By phone number: ${CONTACT_DATA.phone}.`,
                    `By mail: ${CONTACT_DATA.address[1]} ${CONTACT_DATA.address[2]}.`,
                ],
            },
            [`Only You, or a person registered with the California Secretary of State that You authorize to act on
                Your behalf, may make a verifiable request related to Your personal information.`],
            ['Your request to Us must:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    `Provide sufficient information that allows Us to reasonably verify You are the person about whom
                    We collected personal information or an authorized representative.`,
                    `Describe Your request with sufficient detail that allows Us to properly understand, evaluate,
                    and respond to it.`,
                ],
            },
            ['We cannot respond to Your request or provide You with the required information if we cannot:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    'Verify Your identity or authority to make the request.',
                    'And confirm that the personal information relates to You.',
                ],
            },
            [`We will disclose and deliver the required information free of charge within 45 days of receiving Your
                verifiable request. The time period to provide the required information may be extended once by an
                additional 45 days when reasonably necessary and with prior notice.`],
            [`Any disclosures We provide will only cover the 12-month period preceding the verifiable request's
                receipt.`],
            [`For data portability requests, We will select a format to provide Your personal information that is
                readily usable and should allow You to transmit the information from one entity to another entity
                without hindrance.`],
            {tag: 'h2', text: 'Do Not Sell My Personal Information'},
            [`You have the right to opt-out of the sale of Your personal information. Once We receive and confirm a
                verifiable consumer request from You, we will stop selling Your personal information. To exercise
                Your right to opt-out, please contact Us.`],
            {
                tag: 'h1',
                text: '"Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)',
            },
            ['Our Service does not respond to Do Not Track signals.'],
            [`However, some third party websites do keep track of Your browsing activities. If You are visiting
                such websites, You can set Your preferences in Your web browser to inform websites that You do not
                want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of
                Your web browser.`],
            {tag: 'h1', text: 'Children\'s Privacy'},
            [`Our Service does not address anyone under the age of 13. We do not knowingly collect personally
                identifiable information from anyone under the age of 13. If You are a parent or guardian and You
                are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware
                that We have collected Personal Data from anyone under the age of 13 without verification of
                parental consent, We take steps to remove that information from Our servers.`],
            [`If We need to rely on consent as a legal basis for processing Your information and Your country
                requires consent from a parent, We may require Your parent's consent before We collect and use that
                information.`],
            {tag: 'h1', text: 'Your California Privacy Rights (California\'s Shine the Light law)'},
            [`Under California Civil Code Section 1798 (California's Shine the Light law), California residents
                with an established business relationship with us can request information once a year about sharing
                their Personal Data with third parties for the third parties' direct marketing purposes.`],
            [`If you'd like to request more information under the California Shine the Light law, and if You are a
                California resident, You can contact Us using the contact information provided below.`],
            {
                tag: 'h1',
                text: 'California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)',
            },
            [`California Business and Professions Code Section 22581 allows California residents under the age of
                18 who are registered users of online sites, services or applications to request and obtain removal
                of content or information they have publicly posted.`],
            [`To request removal of such data, and if You are a California resident, You can contact Us using the
                contact information provided below, and include the email address associated with Your account.`],
            [`Be aware that Your request does not guarantee complete or comprehensive removal of content or
                information posted online and that the law may not permit or require removal in certain
                circumstances.`],
            {tag: 'h1', text: 'Links to Other Websites'},
            [`Our Service may contain links to other websites that are not operated by Us. If You click on a third
                party link, You will be directed to that third party's site. We strongly advise You to review the
                Privacy Policy of every site You visit.`],
            [`We have no control over and assume no responsibility for the content, privacy policies or practices
                of any third party sites or services.`],
            {tag: 'h1', text: 'Changes to this Privacy Policy'},
            [`We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the
                new Privacy Policy on this page.`],
            [`We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
                effective and update the "Last updated" date at the top of this Privacy Policy.`],
            [`You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                Policy are effective when they are posted on this page.`],
            {tag: 'h1', text: 'Contact Us'},
            ['If you have any questions about this Privacy Policy, You can contact us:'],
            {
                tag: 'ul', className: 'first-nesting', items: [
                    `By email: ${CONTACT_DATA.email}.`,
                    [
                        {tag: 'span', text: 'By visiting this page on our website: '},
                        {
                            tag: 'a',
                            href: 'https://exportery.com',
                            rel: '',
                            target: '_blank',
                            text: 'https://exportery.com',
                        },
                        {tag: 'span', text: '.'},
                    ],
                    `By phone number: ${CONTACT_DATA.phone}.`,
                    `By mail: ${CONTACT_DATA.address[1]} ${CONTACT_DATA.address[2]}.`,
                ],
            },
        ],
    },

    invitation: {
        subtitle: 'Please fill in the form below',
        user_section_title: 'Information about user',
        organization_section_title: 'Information about company',
        button: {
            cancel: 'Cancel',
            confirm: 'Confirm',
        },
    },

    password_requirements: {
        text: 'The password must:',
        list: [
            'be between 8 and 255 characters.',
            'contain only latin letters.',
            'include at least one uppercase letter.',
            // 'include at least one digital.',
            // 'include at least one special symbol (! ? @ # $ % & [ ]).',
        ],
    },

    authorization: {
        request_access_text: 'Sign in or provide your contact details below to find out how you can access the platform',

        sign_in: {
            welcome_title: 'Welcome to exportery',
            description: [
                'Truly industrial e-marketplace',
                'Independent technical surveying',
                'Blockchain-powered Trade Finance solutions',
            ],
            title: 'Sign in',
            two_factor_title: 'Two-factor authentication',
            reset_password_title: 'Reset password',
            email_placeholder: 'User name',
            password_placeholder: 'Password',
            two_factor_code_placeholder: 'Code',
            request_access_question: 'Don’t have an account?',
            required_field_error_message: 'Required field',
            two_factor_text: 'Please, enter the code that has been sent to your email',
            forgot_password_text: 'Please, enter email and we’ll send you a link',
            security_code: {
                text: 'Please, enter code from your security token',
                placeholder: 'Security code',
                required_field_error_message: 'Required field',
            },
            reset_password: {
                subtitle: 'Please fill in the form below',
                button: {
                    cancel: 'Cancel',
                    confirm: 'Confirm',
                },
            },
            button: {
                request_access: 'Request Access',
                sign_in: 'Sign in',
                confirm: 'Confirm',
                reset: 'Reset',
            },
        },

        sign_up: {
            welcome_title: 'Welcome to exportery',
            description: [
                'Please use the registration form below to access the industrial e-marketplace in view mode. We will grant full access to your organization after the accomplishment of the validation process guided by our customer service team.',
                'We hope you will enjoy our platform\'s simplicity, transparency, and proprietary document management system that shifts international procurement of industrial goods and services to a new level of automatization.',
            ],
            title: 'Sign up',
            first_name_placeholder: 'First name',
            last_name_placeholder: 'Last name',
            email_placeholder: 'E-mail',
            organization_name_placeholder: 'Organization',
            country_placeholder: 'Country',
            telephone_placeholder: 'Telephone',
            email_field_error_message: 'The email is not correct',
            required_field_error_message: 'Required field',
            tick_the_areas_label: 'Please tick the areas you are interested in:',
            role: ['My role:', 'Importer', 'Exporter'],
            interest_status: ['Marketplace membership', 'Independent Technical Surveying', 'Trade Finance Services'],
            privacy_policy_text: ['By clicking this box and clicking "Submit", I accept processing to take place in accordance with this form and the ', 'privacy policy', '.'],
            button: {
                submit: 'Submit',
                request_access_btn: 'Request access',
            },
        },
    },

    marketplace: {
        home: {
            all_products_title: 'all products',
        },

        category_page: {
            empty_category_message: 'We are actively working on the creation of the base!',
        },

        product_card: {
            available_status_sold: {value: 'sold', label: 'sold'},
            button: {
                view_product: 'view product',
            },
        },

        product_panel: {
            header: {
                item_label: 'Item',
                condition_label: 'Condition',
                price_label: 'Price',
            },
            delivery_time_label: 'Delivery time',
            exporter_label: 'Exporter',
            supply_conditions_label: 'Supply conditions',
            button: {
                view_product: 'view product',
            },
        },

        product_page: {
            image_alt: 'Product image',
            currency_array: ['USD', 'EUR', 'RUB', 'AED', 'CNY'],
            price_label: '/ Piece',
            offers_from_text: 'from',
            message_about_not_available_currency: 'Please select another currency',
            main_technical_data_label: ['Parameter name', 'Value'],
            details_accordion_label: 'Details',
            interchangeability_text: 'is fully interchangeable with:',
            certification_title: 'Certificates',
            available_currency_table_labels: ['Currency', 'Exchange rate usd'],
            button: {
                add_to_quotation: 'Add to quotation',
                added_to_quotation: 'Added to quotation',
                edit_product: 'Edit',
            },
        },

        quotation: {
            other_offers_title: 'Other offers',
            excel_upload_form: {
                excel_upload_form_text: 'Please upload excel file and fill in the form to get the instant quotation',
                follow_format_tip: ['To upload Excel', 'follow the format'],
                attach_xls_label: 'Attach xls',
                button: {
                    cancel: 'Cancel',
                    upload: 'Upload',
                    confirm_quotation: 'Confirm quotation',
                },
            },

            request_for_supply: {
                title: 'Request for supply',
                find_other_offers_text: 'find otHer offers',
                price_label: '/ Piece',
            },

            other_offers_block: {
                other_offers_title: 'Other offers',
                replace_message: 'Are you sure you want to replace the item?',
                offer: {
                    condition_label: 'Condition',
                    delivery_time_label: 'Delivery time',
                    delivery_time_value: '60 days',
                    exporter_label: 'Exporter',
                    supply_conditions_label: 'Supply conditions',
                },
                button: {
                    replace: 'Replace',
                },
            },

            order_summary_block: {
                title: 'Order summary',
                button: {
                    confirm_quotation: 'confirm quotation',
                },
            },

            empty_request_list: {
                text: 'There is no item yet',
            },

            product_image_alt: 'Product image',

            button: {
                add: 'add item',
                upload: 'upload xls',
                delete_btn: 'delete',
                clear_all: 'clear all',
            },
        },

        search_page: {
            title: 'Search results',
        },
    },

    activity_hub: {
        pages: ['Requests For Quotations', 'E-Contracts'],
        requests_for_quotations: {
            title: 'Activity hub',
            rfq_price_tooltip: 'Please note that the quoted RFQ contains one or more Sub-RFQ\'s denominated in different currencies other than US Dollar. The exchange rate is fixed within the given validity term of the quotation.',
            empty_text: 'There is no RFQ yet',
            table_labels: ['№', 'Type', 'Date', 'Validity Till', 'Days left', 'Total', 'Status'],
            table_row_type_column: {label: 'Type', value: 'Supply'},
            status_row_label: 'Status',
            create_contract_question: 'Are you sure you want to create a contract?',
        },
        e_contracts: {
            title: 'Activity hub',
            empty_text: 'There is no e-contracts yet',
            table_labels_exporter: ['№', 'Type', 'Date', 'Importer', 'Validity Till', 'Total', 'Payment Terms', 'Status'],
            table_labels_importer: ['№', 'Type', 'Date', 'Exporter', 'Validity Till', 'Total', 'Payment Terms', 'Status'],
            status_row_label: 'Status',
            request_question: 'Do you really want to do this?',
            pages: {
                contract_for_supply: {
                    title: 'Contract for supply',
                    status_label: 'Status',
                    total_label: 'Total',
                    contract_information_label: 'Contract information',
                    information_table_labels: ['Document', 'Date', 'Status', 'Before expired, days', 'Action'],
                    table_labels: ['Document', 'Date', 'Status', 'Action'],
                    condition_page: {
                        condition_table_labels: {name: 'Title', value: 'Information'},
                        table_labels: {
                            index_number: '№',
                            part_number: 'Part Number',
                            name: 'Description',
                            quantity: 'Q-ty',
                            doq: 'P-ty',
                            price: 'Price,',
                            total: 'Sum,',
                        },
                        total_label: 'Total',
                        button: {
                            cancel: 'Cancel',
                            review: 'Review',
                            open_dispute: 'Open dispute',
                        },
                    },
                },
                origin_invoice: {
                    title: 'Origin invoice wizard',
                    message: 'Please select the items and their quantity you want to ship',
                    table_labels: ['№', 'Part Number', 'Description', 'Quantity', 'Price', 'Sum'],
                    total_label: 'Total',
                    button: {
                        save: 'Save',
                        cancel: 'Cancel',
                    },
                },
                packing_list_wizard: {
                    title: 'Packing list wizard',
                    delete_place_question: 'Are you sure to delete the place?',
                    pages: {
                        shipment_lot: {
                            place_label: 'Create place',
                            packed_status: 'Packed',
                            empty_message: 'There is no any unpacked product yet',
                            select_data: {
                                label: 'Select method',
                                default_item_label: '- Select -',
                                tooltip: '',
                            },
                            button: {
                                add_serial_number: 'Add s/n',
                                remove_serial_number: 'Delete s/n',
                                add_place: 'Add place',
                                remove_place: 'Delete place',
                                save: 'Save',
                                cancel: 'Cancel',
                            },
                        },
                        packing_list: {
                            table_labels: ['№ Place', ['Description', 'Serial number', 'Q-ty', 'P-ty', 'Net weight per unit, kg', 'Gross weight per unit, kg'], 'Characteristics of the place'],
                            empty_message: 'There is no any packed product yet',
                            unpack_question: 'Are you sure to delete the place?',
                            total_places_label: 'Total places',
                            total_net_weight_label: 'Total net weight',
                            total_gross_weight_label: 'Total gross weight',
                            confirm_question: 'Are you sure you want to create a packing list?',
                            warning_message: 'Please pack all items in your lot to proceed further',
                            unpacking_tooltip: 'Unpacking',
                            button: {
                                add_place: 'Add place',
                                remove_place: 'Delete place',
                                save: 'Save',
                                cancel: 'Cancel',
                                create_packing_list: 'Create packing list',
                            },
                        },
                        labelling: {
                            title: 'Labelling',
                            checkbox_label: 'Select all labels',
                            switcher_label: 'Print with cut line',
                            row_labels: {
                                carrier: 'Carrier',
                                consignee: 'Consignee',
                                content: 'Content',
                                contract: 'Contract',
                                destination: 'Destination',
                                dims: 'DIMS, cm',
                                gross_weight: 'Place Gross Weight',
                                net_weight: 'Place Nett Weight',
                                packing_date: 'Packing Date',
                                shipper: 'Shipper',
                                waybill: 'Voyage no',
                            },
                        },
                    },
                },
                upload_document: {
                    title: 'Document upload',
                    text: 'Please upload document in PDF format:',
                    button: {
                        cancel: 'Cancel',
                        create: 'Create',
                        btn_text: 'or',
                    },
                },
            },
        },
        place_product: {
            warranty_title: 'Warranty',
            from_excel: {
                text: 'Please upload excel file to place your products:',
                button: {
                    cancel: 'Cancel',
                    confirm: 'Go to my products',
                },
            },
            from_form: {
                title: 'Place product',
                edit_title: 'Edit product',
                sections: {
                    product_information: {
                        label: 'Product information',
                        sub_tooltip: [
                            null, null, null, null,
                            [
                                {
                                    title: 'New',
                                    text: 'The product is New, recent years of the manufacture, packed in the original manufacturer\'s coverage (if the producer foresaw such coverage), has all accompanying documentation, e.g. original passports, labels, log-books. The duplicates of the documents are not accepted. The product has no signs of dismantling and installation on the object. The manufacturer\'s seals should remain intact. The assigned storage time should be valid for at least two months. The manufacturer\'s warranty is full and specified in the documentation.',
                                },
                                {
                                    title: 'Overhauled',
                                    text: 'Overhauled – the product overhauled by the Authorized OH plant or repair shops, packed in the coverage ensuring its safe transportation by any means of transport, has had all accompanying documentation, e.g., original passports, labels, log-books. The duplicates of the documents are accepted if an authorized OH organization issues them. The product can have signs of dismantling and installation on the object but should contain seals from the OH organization. The assigned storage time should be valid for at least two months. The OH warranty is full and appropriately specified in the documentation.',
                                },
                                {
                                    title: 'Used',
                                    text: 'Used - the product is used, but should have all the signs of structural integrity, packed in the coverage ensuring its safe transportation by any means of transport, has all accompanying documentation, e.g., original passports, labels, log-books.',
                                },
                                {
                                    title: 'From storage',
                                    text: `From storage – The product has all the features specified in the option New but has expired assigned storage time and/or the manufacturer's warranty. Example: Hyrohorizon AGB-3K manufactured in 1990 storage according to all technical conditions, in the original manufacturer's coverage.`,
                                },
                            ],
                            {
                                title: 'Plant of Overhaul',
                                text: 'Please choose from pre-selected names or input new ones.',
                            },
                            {
                                title: 'Date of overhaul',
                                text: 'Please specify the date of overhaul indicated in the accompanying documents, e.g., passports, logbooks, labels.',
                            },
                            {
                                title: 'Overhaul count',
                                text: 'Please specify the count of overhauls, e.g., either it is 1st OH or 2nd OH during the product\'s lifetime.',
                            },
                            {
                                title: 'Exporter\'s warranty',
                                text: 'The warranty provided solely by the Exporter stipulates that possible reclamation will be filled for the Exporter within the specified validity term.',
                            },
                            {
                                title: 'Package date',
                                text: 'Please specify the date of packaging shown on the label of the product if such exists.',
                            },
                            {
                                title: 'Conservation till',
                                text: 'Means lead time until the product\'s storage time is expired.',
                            },
                            {
                                title: 'Manufacturer',
                                text: 'Please choose from pre-selected names or input new ones.',
                            },
                            {
                                title: 'Date of manufacture',
                                text: 'Please specify the date of packaging shown on the product\'s label if such exists.',
                            },
                            {
                                title: 'Supply conditions',
                                text: 'The listed product should reflect delivery to our delivery points as per Incoterms 2020. Currently, we are operating through the Borispol Airport Cargo Terminal, Ukraine so the only available shipment mode is FCA Borispol Airport Cargo Terminal, Ukraine.',
                            },
                            null,
                            {
                                title: 'Delivery Lead Time',
                                text: 'This means the supply lead times in days, from the date of committing a deal to the date specified in export customs declaration at the origin or any other transport bill. Please carefully assess that time to avoid sanctions for the late delivery.',
                            },
                            {
                                title: 'Available quantity',
                                text: 'Please specify the available quantity you can supply within the valid delivery lead time.',
                            },
                            {
                                title: 'MOQ',
                                text: 'Minimum qouantity of goods you can ship to our service point',
                            },
                            {
                                title: 'DOQ "Denomination of Quantity"',
                                text: 'Please select either a single item (pc) or set containing multiple blocks, units, etc., in your product.',
                            },
                            {
                                title: 'Price',
                                text: 'Should be specified per 1 unit.',
                            },
                            {
                                title: 'Currency',
                                text: 'Please note that all your listings should be quoted in US Dollars by default on the Exportery marketplace even if you remove US Dollars as the mean of payment for your products in your Payment Setup.',
                            },
                            {
                                title: 'Offer validity',
                                text: 'Term in days within which your offer will be valid on the marketplace as quoted parameters, including price. Within that term, you cannot adjust the core conditions of that block.',
                            },
                        ],
                    },
                    delivery_and_payment: {
                        label: 'Delivery & Payment',
                        subsection: {
                            delivery_payment: {
                                tooltip: [
                                    {
                                        title: 'Means of Payment',
                                        text: 'Currently, we provide a means of payment - a payment against a guaranteed escrow warehouse receipt stating the arrival of the goods on our transit bonded warehouse in the Abu Dhabi cargo airport terminal. That method of payment is simple: once your goods arrive at AbuDhabi you will be paid according to the conditions of the Contract.',
                                    },
                                    {
                                        title: 'Currency',
                                        text: 'You can tick the currencies you can sell your product on Exportery.com. Please note that you can select as many currencies as you would like, but the settlement is only available in one currency when the Quotation is established. Example: You have quoted all your products on the marketplace in CNY and EUR. When your contractual party selects one of your products in CNY, the other products can be sold only through CNY.',
                                    },
                                ],
                            },
                            regulatory_framework: {
                                title: 'Regulatory framework',
                                tooltip: [
                                    {
                                        title: 'Required from Importer',
                                        text: 'Please assess and select obligatory documents you will be required to submit to Export Control authorities at the Origin.\n' +
                                            '\n' +
                                            'Three options are available: \n' +
                                            '\n' +
                                            'End-User Certificate from the Ministry of Defence of the destination country\n' +
                                            'End-User Certificate from Civil Air Operator\n' +
                                            'End-User Certificates are not required to export goods from the Origin country',
                                    },
                                ],
                            },
                            shipment_conditions: {
                                title: 'Shipment conditions',
                                tooltip: [
                                    {
                                        title: 'Shipment mode',
                                        text: 'Please select the designated shipment mode you want to ship your goods',
                                    },
                                ],
                            },
                            packaging: {
                                title: 'Packaging',
                                tooltip: [
                                    null, null,
                                    {
                                        title: 'Packaging type',
                                        text: 'Please select material of you packaging',
                                    },
                                    null, null, null,
                                    {
                                        title: 'Measurеment',
                                        text: 'Please check the measurement units of your goods',
                                    },
                                ],
                            },
                            dangerous_goods_information: {
                                title: 'Dangerous goods information',
                                tooltip: {
                                    title: 'Dangerous goods information',
                                    text: 'Please fill in that section carefully and check the dangerous goods certification for your goods or contact our customer service representative',
                                },
                            },
                        },
                    },
                    photo: {
                        label: 'Photo',
                        same_name_message: ['Photos with these names have already been added:', 'Please rename them or add others!'],
                        quantity_message: ['A maximum quantity of', 'pieces.', 'Of which', 'have already been added!'],
                        size_message: ['Photos size are larger than', 'Mb:'],
                        invalid_types_label: 'Files invalid type:',
                        rejected_files_label: 'Rejected files',
                        alt_label: 'Image alt',
                        tooltip: {
                            title: 'Photo',
                            text: 'Please upload the photos of your product, a maximum quantity of 10 pieces. The platform can accept photos in the following formats: .jpeg, .jpg, .png in a maximum size of 10 Mb each.',
                        },
                    },
                    main_technical_data: {
                        label: 'Main technical data',
                        tooltip: {
                            title: 'Main technical data',
                            text: 'Please fill in the cells parameter name and value if you would like to upload your product\'s technical content in the form of an organized table.  You can upload your content as plain text as well through the agency of text area.',
                        },
                        button: {
                            add: 'Add item',
                            remove: 'Delete item',
                        },
                    },
                    resources: {
                        label: 'Resources',
                        warranty_title: 'Warranty',
                        tooltip: {
                            title: 'Resources',
                            text: 'Please fill in the resources cells actual data that is reflected in the technical documents of the product. Fill in the the value of Manufacturer\'s warranty or warranty of the Overhaul organization',
                        },
                    },
                    supply_set: {
                        label: 'Supply set',
                        tooltip: {
                            title: 'Supply Set',
                            text: 'Please specify each part number that poses the supply set for the particular product. You can choose the part number of each item from our vast database (please tick "choose from products" in that case).',
                        },
                    },
                    certification: {
                        label: 'Certification',
                        same_name_message: ['Files with these names have already been added:', 'Please rename them or add others!'],
                        quantity_message: ['A maximum quantity of', 'files for each type.', 'Of which', 'have already been added to this type of certificate!'],
                        tooltip: {
                            title: 'Certification',
                            text: 'Please upload the certificates of your product, a maximum quantity of 10 files. The platform can accept files in the following formats: .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg in a maximum size of 10 Mb each.',
                        },
                    },
                    product_guides_and_documents: {
                        label: 'Product guides and documents',
                        same_name_message: ['Files with these names have already been added:', 'Please rename them or add others!'],
                        quantity_message: ['A maximum quantity of', 'files.', 'Of which', 'have already been added!'],
                        tooltip: {
                            title: 'Product guides and documents',
                            text: 'Please upload the Product Guides and documents of your goods, a maximum quantity of 10 files. The platform can accept files in the following formats: .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg in a maximum size of 10 Mb each.',
                        },
                    },
                    shipment_guides_and_documents: {
                        label: 'Shipment guides and documents',
                        same_name_message: ['Files with these names have already been added:', 'Please rename them or add others!'],
                        quantity_message: ['A maximum quantity of', 'files.', 'Of which', 'have already been added!'],
                        tooltip: {
                            title: 'Shipment guides and documents',
                            text: 'Please upload the Shipment Guides and documents of your goods, a maximum quantity of 10 files. The platform can accept files in the following formats: .pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg in a maximum size of 10 Mb each.',
                        },
                    },
                },
                required_message: ['Fields with', '*', 'are required'],
                required_field_error_message: 'Please fill in all required fields',
                no_documents_text: 'No documents to display',
                button: {
                    skip: 'Skip',
                    save: 'Save',
                    cancel: 'Cancel',
                    place_product: 'Place product',
                    edit_product: 'Update product',
                    clear_form: 'Clear form',
                },
            },
        },
        my_products: {
            title: 'My products',
            device_info_message: ['Please update your profile and use the desktop device in order to create or edit products'],
            product_panel: {
                header: {
                    id_label: 'Id',
                    item_label: 'Item',
                    condition_label: 'Condition',
                    price_label: 'Price',
                },
                views_label: 'Views',
            },
            product_card: {
                id_label: 'ID',
                views: 'Views',
            },
            empty_my_products_message: 'You haven\'t added anything yet',
            delete_offer_question: 'Are you sure you want to delete this offer?',
            button: {
                upload_xls: 'upload xls',
                upload_from_form: 'fill in the form',
            },
        },
        profile: {
            title: 'Profile',
            pages: {
                payment_setup: {
                    bank_accounts_title: 'Bank accounts',
                    no_accounts_message: 'There are no accounts yet',
                    default_account: 'Default account',
                    set_as_default: 'Set as default',
                    pg_draft: [
                        ['PG DRAFT SWIFT MT', '(NARRATIVE FIELD 79)'],
                        ['THE BANK GUARANTEE NO', 'DD', 'WAS ISSUED BY RAS BANK, UAE BY THE ORDER OF EXPORTERY LTD, ABU DHABI, UAE, ID CODE 01001), IN FAVOUR OF', 'FOR AMOUNT', 'WITH THE FOLLOWING CONTENT:'],
                        ['DATE:'],
                        ['CONTRACT NUMBER AND TITLE', ': FOR THE SUPPLY OF SPARE PARTS TO'],
                        ['BANK\'S BRANCH OR OFFICE:'],
                        ['BENEFICIARY:'],
                        ['WE HAVE BEEN INFORMED THAT EXPORTERY LTD  (HEREINAFTER CALLED THE “SELLER”) HAS ENTERED INTO CONTRACT NO', 'DD', 'WITH YOU, FOR THE SUPPLY OF SPARE PARTS TO', '(HEREINAFTER CALLED \'THE CONTRACT\'). FURTHERMORE, WE UNDERSTAND THAT, ACCORDING TO THE CONDITIONS OF THE CONTRACT, A PERFORMANCE GUARANTEE IS REQUIRED.'],
                        ['AT THE REQUEST OF THE SUPPLIER, WE HEREBY IRREVOCABLY UNDERTAKE TO PAY YOU ANY SUM(S) NOT EXCEEDING', 'UPON RECEIPT BY US OF YOUR FIRST DEMAND IN WRITING DECLARING THE SUPPLIER TO BE IN DEFAULT UNDER THE CONTRACT, WITHOUT CAVIL OR ARGUMENT, OR YOUR NEEDING TO PROVE OR TO SHOW GROUNDS OR REASONS FOR YOUR DEMAND OR THE SUM SPECIFIED THEREIN.'],
                        ['THIS GUARANTEE SHALL ENTER INTO FORCE ON THE TERMS SET OUT IN THE CONTRACT OF BANK GUARANTEE NO', 'OF', 'AND WILL BE VALID UNTIL THE'],
                        ['THIS GUARANTEE SHALL EXPIRE, NO LATER THAN THE OF', 'AND ANY DEMAND FOR PAYMENT UNDER IT MUST BE RECEIVED BY US AT THIS OFFICE ON OR BEFORE THAT DATE. HOWEVER, BEFORE THAT EXPIRATION DATE, IF THE PLANNED CONTRACT EXECUTION PERIOD HAS BEEN DELAYED OR EXTENDED, OR ITS VALUE INCREASED, THE EXPORTER SHALL RESPECTIVELY EXTEND THE VALIDITY PERIOD OF THIS PERFORMANCE SECURITY OR INCREASE ITS AMOUNT ACCORDINGLY.'],
                        ['THIS GUARANTEE IS SUBJECT TO THE UNIFORM RULES FOR DEMAND GUARANTEES, ICC PUBLICATION NO. 458, EXCEPT THAT SUBPARAGRAPH (II) OF SUB-ARTICLE 19(A) IS HEREBY EXCLUDED.'],
                        ['THANKS FOR COOPERATION,'],
                        ['BEST REGARDS,'],
                    ],
                    button: {
                        add_account: 'Add bank account',
                    },
                },
                shipment_setup: {
                    default_label: 'Default',
                    set_as_default: 'Set as default',
                    no_items_message: 'There is nothing here yet',
                    button: {
                        consignee: 'Add consignee',
                        notifyparty: 'Add notify party',
                        shipper: 'Add shipper',
                        importerforwarder: 'Add forwarder',
                        exporterforwarder: 'Add forwarder',
                    },
                },
                shipment_organization: {
                    create_consignee_title: 'Create consignee details',
                    create_notify_title: 'Create notify party',
                    create_forwarder: 'Create forwarder',
                    create_shipper_title: 'Create shipper',
                    edit_consignee_title: 'Edit consignee details',
                    edit_notify_title: 'Edit notify party',
                    edit_forwarder: 'Edit forwarder',
                    edit_shipper_title: 'Edit shipper',
                    default_change_question: 'Are you sure you want to change the default item?',
                },
                bank_account: {
                    create_account_title: 'Create bank account',
                    edit_account_title: 'Edit bank account',
                    default_change_question: 'Are you sure you want to change the default bank account?',
                },
                access_setup: {
                    title: 'Access setup',
                },
            },
            button: {
                access_setup: 'Access setup',
                save: 'Save',
                cancel: 'Cancel',
            },
        },
        notifications: {
            title: 'Notifications',
            filters: ['All', 'Unread'],
            no_notifications_message: {
                all: 'You have no notifications yet',
                unread: 'You have no unread notification',
            },
            delete_current_notification_question: 'Are you sure you want to delete this notification?',
            delete_question: 'Are you sure you want to delete the selected notifications?',
            button: {
                select: 'Select',
                cancel: 'Cancel',
                delete_btn: 'Delete',
                show_more: 'Show more',
            },
        },
        upload_documents: {
            same_name_message: ['Files with these names have already been added:', 'Please rename them or add others!'],
            quantity_message: ['A maximum quantity of', 'files.', 'Of which', 'have already been added!'],
        },
    },

    search: {
        input_placeholder: 'Search',
        result_titles: ['Platform', 'Title'],
    },

    hub_menu: {
        link_labels: {
            quotation: 'Quotation',
            quotation_request_for_supply: 'Request for supply',
            quotation_request_for_overhaul: 'Request for overhaul',
            activity_hub: 'Activity hub',
            my_products: 'My products',
            notifications: 'Notifications',
            profile: 'Profile',
            log_out: 'Log out',
        },
        user_name: 'User',
        profile_img_alt: 'Profile icon',
    },

    header: {
        button_menu: {
            importer: {
                btn_label: 'Quotation',
                menu: [
                    {
                        label: 'Request for Supply',
                        href: '/marketplace/quotation',
                        isDisabled: false,
                    },
                    {
                        label: 'Request for Overhaul',
                        isDisabled: true,
                    },
                ],
            },
            exporter: {
                btn_label: 'Place product',
                menu: [
                    {
                        label: 'Batch upload',
                        href: hubURLs.products_excel,
                    },
                    {
                        label: 'Manual upload',
                        href: hubURLs.product_create_manual,
                    },
                ],
            },
        },
        trial_identifier: {
            label: 'Trial mode',
            tooltip: {
                text: `Please fill in profile data or contact customer 
                service to get a full scale access.`,
            },
        },
        button: {
            sign_in: 'Sign in',
        },
    },

    footer: {
        text: 'EXPORTERY is a secure industrial e-trade platform encompassing a global spare parts and components marketplace for end-users and exporters in the aviation and land transport industries outfitted with a proprietary electronic document management system that elevates cross-border procurement efficacy and safety to a new level.',
        navigation_links: [
            {
                id: 'contact_us',
                title: 'contact us',
                url: '/contact-us',
            },
            {
                list: [
                    {
                        id: 'about-marketplace',
                        title: 'How Exportery works',
                        url: staticURLs.about_marketplace,
                    },
                    {
                        id: 'about-independent-technical-surveying',
                        title: 'Independent technical surveying',
                        url: staticURLs.about_independent_technical_surveying,
                    },
                    {
                        id: 'about-core-services',
                        title: 'Core services',
                        url: staticURLs.about_core_services,
                    },
                    {
                        id: 'news',
                        title: 'News',
                        url: URLs.news,
                    },
                    {
                        id: 'terms_and_conditions',
                        title: 'terms and conditions',
                        url: staticURLs.terms_and_conditions,
                    },
                    {
                        id: 'privacy_policy',
                        title: 'Privacy policy',
                        url: staticURLs.privacy_policy,
                    },
                ],
            },
            {},
        ],
        social_links: [
            {
                id: 'linkedin',
                url: CONTACT_DATA.linkedin,
                img: 'linkedin',
            },
            {
                id: 'facebook',
                url: CONTACT_DATA.facebook,
                img: 'facebook',
            },
            {
                id: 'twitter',
                url: CONTACT_DATA.twitter,
                img: 'twitter',
            },
        ],
        contact_address: CONTACT_DATA.address_short,
        contact_email: CONTACT_DATA.email,
        contact_phone: CONTACT_DATA.phone,
        copyright: `Copyright © ${getCurrentYear()} EXPORTERY all rights reserved`,
    },

    filters: {
        filters_label: 'Filters',
        part_number_label: 'Enter part number',
        part_number_label_mobile: 'Part number',
        title_label: 'Title',
        platform_label: 'Platform',
        organization_label: ['Exporter', 'Importer'],
        search_label: 'Search',
        button: {
            show_more: 'show more',
            show_less: 'show less',
        },
    },

    sorting: {
        sorting_label: 'Sorting',
        sorting_title: 'Sort by:',
    },

    filters_sorting_button: {
        show_result: 'show result',
        reset_all: 'Reset all',
        reset_pnum: 'Reset part number filter',
    },

    breadcrumbs: new BreadcrumbsConstructor()
        .creator({
            notifications: ['Notifications'],
            profile: ['Profile'],
            access_setup: ['Profile', 'Access setup'],
            bank_account_create: ['Profile', 'Create bank account'],
            bank_account_update: ['Profile', 'Edit bank account'],
            consignee_details_create: ['Profile', 'Create consignee details'],
            consignee_details_update: ['Profile', 'Edit consignee details'],
            exporter_forwarder_create: ['Profile', 'Create forwarder'],
            exporter_forwarder_update: ['Profile', 'Edit forwarder'],
            importer_forwarder_create: ['Profile', 'Create forwarder'],
            importer_forwarder_update: ['Profile', 'Edit forwarder'],
            notify_party_create: ['Profile', 'Create notify party'],
            notify_party_update: ['Profile', 'Edit notify party'],
            shipper_create: ['Profile', 'Create shipper'],
            shipper_update: ['Profile', 'Edit shipper'],
            quotation_excel: ['Marketplace', 'Request for supply', 'Upload XLS'],
            quotation_excel_format: ['Marketplace', 'Request for supply', 'Upload XLS', 'Format'],
            independent_technical_surveying: ['Independent technical surveying'],
            core_services: ['Core services'],
            terms_and_conditions: ['Terms and Conditions'],
            privacy_policy: ['Privacy Policy'],
            request_access_page: ['Request access'],
            not_found_page: ['Page not found'],
            not_acceptable_page: ['Page not acceptable'],
            internal_server_error_page: ['Internal server error'],
            contract_for_supply_invoice: ['Invoice create'],
            contract_for_supply_packing_list: ['Packing list wizard'],
            contract_for_supply_labelling: ['Labelling'],
            document_page: ['Document'],
        }, 'Home', session),

    error_page: {
        title: 'Sorry',
        route_not_found: 'Page not found',
        service_under_development: 'the page is under construction',
        internal_server_error: [
            'Well, this is unexpected…',
            'Error code: 500',
            'An error has occurred and we are working to fix the problem.',
        ],
        button: {
            go_back: 'Go back',
            go_to_homepage: 'Go to the home page',
        },
    },

    alert_modal: {
        delete_item_question: 'Are you sure you want to delete the item?',
        delete_last_item_question: 'Are you sure you want to delete the last item?',
        delete_all_items_question: 'Are you sure you want to delete all items?',
        clear_all_question: 'Are you sure you want to clear all?',
        clear_form_question: 'Are you sure you want to clear form?',
        session_expired_message: 'Dear User, your session has expired. Please sign in again!',
        redirect_disclaimer: 'Please use desktop version to benefit all features of the product.',
        permission_denied: 'Permission denied for your user!',
        close_modal_window_question: 'Unsaved data will be lost! Do you want to close the form?',
        button: {
            ok: 'ok',
            cancel: 'cancel',
        },
    },

    item_counter: {
        empty_error_message: 'Cannot be empty!',
        less_error_message: 'The quantity cannot be less than 1 piece',
        more_error_message: ['The quantity cannot be more than', 'piece'],
    },

    dropzone: {
        text: ['Drop your file here or', 'browse', 'File must be'],
        not_for_desktop_text: ['Browse', 'the file from your device'],
    },

    file_container: {
        error_message: 'File must be',
        button: {
            delete_btn: 'Delete',
        },
    },

    excel_response_info: {
        row_id: 'Excel row',
        filters: ['All', 'Only errors'],
        no_errors: 'No errors to show',
        button: {
            clear_all: 'Clear all',
        },
    },

    excel_format_page: {
        quotation_text: 'Please adhere to the RFQ example in .xls format to get your instant quotation',
        place_product_text: 'Please adhere to the example below to place your products on the e-marketplace:',
        quotation_tip: 'Please download an example of the .xls file RFQ',
        place_product_tip: 'Please download an example of the .xls file',
        quotation_table: {
            headers: ['Part Number', 'Description', 'DOQ', 'Quantity'],
            body: [
                {
                    part_number: 'LFS-PS27-450',
                    description: 'Lamp',
                    doq: 'pc',
                    quantity: '5',
                },
                {
                    part_number: 'АGB-3К Series 3',
                    description: 'Gyro Horizon',
                    doq: 'pc',
                    quantity: '1',
                },
                {
                    part_number: '463B',
                    description: 'Fuel Pump',
                    doq: 'pc',
                    quantity: '2',
                },
                {
                    part_number: 'RU-19А-300',
                    description: 'APU',
                    doq: 'set',
                    quantity: '1',
                },
                {
                    part_number: 'KURS-MP70',
                    description: 'Navigation and Landing System',
                    doq: 'set',
                    quantity: '1',
                },
                {
                    part_number: 'GA-192T',
                    description: 'Valve',
                    doq: 'pc',
                    quantity: '2',
                },
                {
                    part_number: '12-SAM-28',
                    description: 'Battery',
                    doq: 'pc',
                    quantity: '2',
                },
                {
                    part_number: '1300x480',
                    description: 'Main Tyre',
                    doq: 'pc',
                    quantity: '2',
                },
            ],
        },
        place_product_table: {
            headers: ['Part Number', 'Part Number(Cyrillic)', 'Description', 'Section', 'DOQ', 'Quantity', 'Condition', 'Price, USD', 'Currency', 'Date of Manufacture / OH', 'Offer validity, days', 'Delivery lead time, days', 'Required from Importer', 'Supply conditions (Incoterms 2020)', 'Supply point'],
            body: [
                {
                    part_number: 'VR-14',
                    part_number_cyrillic: 'ВР-14',
                    description: 'Gear box',
                    section: 'Helicopters',
                    doq: 'pc',
                    quantity: '3',
                    condition: 'NEW',
                    price: '700000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'end-user documents are not required',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8АТ-9800-00',
                    part_number_cyrillic: '8АТ-9800-00',
                    description: 'Tow bar',
                    section: 'Ground Equipment',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NEW',
                    price: '2000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'end-user documents are not required',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8АТ-9803-00',
                    part_number_cyrillic: '8АТ-9803-00',
                    description: 'MAT CAR FOR WORKING UNDER FUSELAGE',
                    section: 'Ground Equipment',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NEW',
                    price: '400',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'end-user documents are not required',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8АТ-9924-00',
                    part_number_cyrillic: '8АТ-9924-00',
                    description: 'SLING FOR LIFTING TAIL BOOM',
                    section: 'Ground Equipment',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NEW',
                    price: '2100',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'end-user documents are not required',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8-9923-00',
                    part_number_cyrillic: '8-9923-00',
                    description: 'OIL PAN',
                    section: 'Ground Equipment',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NEW',
                    price: '300',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'end-user documents are not required',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '246-3901-000',
                    part_number_cyrillic: '246-3901-000',
                    description: 'ANTI-TORQUE PROPELLER',
                    section: 'Helicopters, Transport Aviation',
                    doq: 'set',
                    quantity: '10',
                    condition: 'NEW',
                    price: '180000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'end-user documents are not required',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8-1960-000',
                    part_number_cyrillic: '8-1960-000',
                    description: 'SWASH PLATE',
                    section: 'Helicopters',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NEW',
                    price: '80000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'end-user documents are not required',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '246-3922-00',
                    part_number_cyrillic: '246-3922-00',
                    description: 'BLADES FOR ANTI-TORQUE',
                    section: 'Helicopters',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NEW',
                    price: '60000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'end-user documents are not required',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '610200А',
                    part_number_cyrillic: '610200А',
                    description: 'VALVE ELECTROMAGNETIC',
                    section: 'Helicopters',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NEW',
                    price: '1100',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'end-user documents are not required',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
                {
                    part_number: '8AT-2710-000',
                    part_number_cyrillic: '8AT-2710-000',
                    description: 'MAIN ROTOR BLADES',
                    section: 'Helicopters',
                    doq: 'pc',
                    quantity: '10',
                    condition: 'NEW',
                    price: '190000',
                    currency: 'USD, EUR, AED',
                    date: '07.03.2021',
                    offer_validity: '180',
                    delivery_lead_time: '45',
                    required_from_importer: 'end-user documents are not required',
                    supply_conditions: 'FCA',
                    supply_point: 'KBP',
                },
            ],
        },
        button: {
            back: 'Back',
        },
    },

    input_phone: {
        search_placeholder: 'Search',
        search_not_found: 'No entries to show',
    },

    input_field: {
        input_type_message: 'Please, enter',
        input_type: {
            'int': 'a positive integer or 0',
            'float': 'a positive integer, decimal number or 0',
        },
    },

    select: {
        search_field_placeholder: 'Search',
        search_option_not_found: 'No entries to show',
        default_selected_label: '- Select -',
    },

    select_autocomplete: {
        no_options_message: 'no options',
    },

    pagination: {
        button: {
            prev: 'Prev',
            next: 'Next',
        },
    },

    page_to_print: {
        title: 'Page to print',
        button: {
            cancel: 'Cancel',
            print: 'Print',
        },
        print_blob_button_alt: 'Print',
    },

    document_viewer: {
        document_not_exist: 'There is no document to display!',
        document_error: 'An error occurred while uploading the file!',
        document_loading_failed: 'Failed to load PDF file.',
        pdf_no_data: 'No PDF file specified.',
        page_error: 'Failed to load the page.',
        page_no_data: 'No page specified.',
    },

    cookie_consent: {
        title: 'Cookie consent',
        text: 'This website uses cookies that help the website to function and also to track how you interact with our website. But for us to provide the best user experience, enable the specific cookies from Settings, and click on Accept.',
        buttons: {
            reject_all: 'Reject all',
            accept_all: 'Accept all',
        },
    },

    sweet_alert_static_data: {
        error_response_place_product: 'Errors:',
    },

    logo_alt: 'Logo',
});

export default en;