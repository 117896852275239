import React, {Component} from 'react';
import {useNumericFormat, NumberFormatBase} from 'react-number-format';
import PropTypes from 'prop-types';

import {leftTooltipPositionHandler} from '../tooltip-creator/handlers';
import {withDataService} from '../hoc';

import TooltipCreator from '../tooltip-creator';

import classnames from 'classnames/bind';
import styles from './input-field.module.scss';

const Input = React.forwardRef((props, ref) =>
    <input ref={ref} {...props} autoComplete="off"/>
);

const CustomNumericInput = (props) => {
    const {numberType, pasteValue, onPasteCaptureCallback, ...restProps} = props;

    const {
        format,
        removeFormatting,
        ...rest
    } = useNumericFormat(restProps);

    const zeroCheckHandler = (value, allowLeadingZeros = true) => {
        /* check if zero stays in the first position */
        const regExpZero = /0/;
        const strWithZero = value.match(regExpZero);
        if (strWithZero) {
            const zeroIdx = strWithZero.index;
            if (!allowLeadingZeros) {
                if (value.length > 1 && zeroIdx === 0) {
                    const dot = value.match(/\./);
                    value = dot && dot.index === 1 ? value.slice(2) : value.slice(1);
                }

            } else {
                if (zeroIdx === 0) {
                    const dot = value.match(/\./);
                    if ((!dot && value.length > 1) || (dot && dot.index > 1)) {
                        return value.slice(1);
                    }
                }
            }
        }

        return value;
    }

    const replaceHandler = (value, arr, replaceStr = '') => {
        /* arr is a string or an array */
        for (let i = 0; i < arr.length; i++) {
            value = value.replace(arr[i], replaceStr);
        }

        return value;
    };

    const formatHandler = (value) => {
        if (value.length > 3) {
            /* reverse string */
            let reverseStr = value.split('').reverse().join('');

            /* count number of thousandth parts in string
            and add empty symbol between group of symbols in string */
            const reverseStrLength = reverseStr.length;
            const numOfThousandths = Math.trunc(reverseStrLength / 3);

            let arrReverseOfThousandths = [];
            for (let i = 0; i < numOfThousandths; i++) {
                const reverseSubStr = reverseStr.slice(i * 3, i * 3 + 3);
                const subStr = reverseSubStr.split('').reverse().join('');
                arrReverseOfThousandths.push(subStr);

                /* check last part of string when reverseStrLength % 3 !== 0 */
                if (i === numOfThousandths - 1) {
                    const lastReverseSubStr = reverseStr.slice(i * 3 + 3);
                    if (lastReverseSubStr.length > 0) {
                        const lastSubStr = lastReverseSubStr.split('').reverse().join('');
                        arrReverseOfThousandths.push(lastSubStr);
                    }
                }
            }

            return arrReverseOfThousandths.reverse().join(' ');
        }

        return value;
    };

    /* first step for transform value - remove format */
    const onRemoveFormatting = (value) => {
        if (value === '') {
            return value;
        }

        /* search and replace all symbol on empty str (excluded digital, comma and dot) */
        const regExpAllSymbol = /\D/g;
        const symbolInStr = value.match(regExpAllSymbol);
        if (symbolInStr) {
            const excludedCommaAndDot = symbolInStr.filter(symbol => symbol !== ',' && symbol !== '.');

            if (excludedCommaAndDot.length > 0) {
                value = replaceHandler(value, excludedCommaAndDot);
            }
        }

        /* search and replace all comma on dot */
        const regExpComma = /,/g;
        const strWithComma = value.match(regExpComma);
        if (strWithComma) {
            value = value.replace(regExpComma, '.');
        }

        /* search and replace all dot (excluded last dot in string) */
        const regExpDot = /\./g;
        const strWithDot = value.match(regExpDot);
        if (strWithDot) {
            if (!pasteValue) {
                value = replaceHandler(value, strWithDot.slice(0, strWithDot.length - 1));

            } else {
                for (let i = 0; i < strWithDot.length; i++) {
                    if (i === strWithDot.length - 1) {
                        const idx = value.match(/\./).index + 1;

                        if (value.length - idx <= 2) {
                            break;
                        }
                    }

                    value = value.replace(strWithDot[i], '');
                }

                onPasteCaptureCallback();
            }
        }

        /* check if dot stays in the first position and zero leading */
        if (value.includes('.')) {
            if (value.match(/\./).index === 0) {
                value = `0${value}`;
            }
        }

        /* round value in depends on type (float, int) */
        if (numberType === 'float') {
            value = zeroCheckHandler(value);

            if (value.includes('.')) {
                /* set maximum 2 decimal numbers */
                const arr = value.split('.');
                if (arr[1].length > 2) {
                    const fractionalPart = arr[1].slice(0, 2);
                    const updatedArr = [arr[0], fractionalPart];
                    value = updatedArr.join('.');
                }
            }
        }

        if (numberType === 'int') {
            value = zeroCheckHandler(value, false);
            value = parseInt(value).toString();
        }

        return removeFormatting(value);
    };

    /* second step for transform value - set format */
    const setFormat = (numStr) => {
        let str = format(numStr);
        if (str === '') {
            return str;
        }

        if (str.includes('.')) {
            const arr = str.split('.');
            const integerPart = formatHandler(arr[0]);
            const fractionalPart = arr[1];
            str = fractionalPart.length > 0 ? `${integerPart},${fractionalPart}` : `${integerPart},`;

        } else {
            str = formatHandler(str);
        }

        return str;
    };

    return (
        <NumberFormatBase
            format={setFormat}
            removeFormatting={onRemoveFormatting}
            {...rest} />
    );
};

class InputField extends Component {
    state = {
        leftTooltipPosition: 0,
        typeError: null,
        isVisiblePassword: false,
        pasteValue: false,
    };

    inputRef = React.createRef();
    labelRef = React.createRef();

    timerId = null;

    componentDidMount() {
        this.onInitLeftTooltipPosition();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.label !== this.props.label) {
            this.onInitLeftTooltipPosition();
        }
    }

    onInitLeftTooltipPosition = () => {
        if (this.labelRef.current) {
            const updatedLeftTooltipPosition = leftTooltipPositionHandler(this.labelRef.current);
            this.setState({leftTooltipPosition: updatedLeftTooltipPosition});
        }
    };

    onTogglePasswordVisibility = () => {
        this.setState(({isVisiblePassword}) => ({isVisiblePassword: !isVisiblePassword}));
    };

    onInputChange = (e, numberType) => {
        const {target: {name, value}} = e;
        this.onRemoveInputError(name);

        if (numberType === 'float') {
            if (this.inputRef.current) {
                if (this.props.value.formattedValue && value.formattedValue) {
                    const {formattedValue: prevFormattedValue} = this.props.value;
                    const {formattedValue} = value;
                    if (prevFormattedValue.includes(',') && formattedValue.includes(',')) {
                        const caretPosition = this.inputRef.current.selectionStart;
                        const prevCommaIdx = prevFormattedValue.match(/,/).index + 1;
                        const commaIdx = formattedValue.match(/,/).index + 1;

                        if (prevCommaIdx > caretPosition && commaIdx === caretPosition) {
                            const updatedCaretPosition = caretPosition - 1;
                            this.inputRef.current.blur();
                            this.inputRef.current.focus();
                            this.inputRef.current.setSelectionRange(updatedCaretPosition, updatedCaretPosition);
                        }
                    }
                }
            }
        }

        this.props.onInputChange(e);
    };

    onInputFocus = (e) => {
        this.onRemoveInputError(e.target.name);
        this.props.onInputFocus(e);
    };

    onValueCheck = ({value}, numberType, name) => {
        let valid = true;
        if (this.props.value === '') {
            if (value === '' || value < 0) {
                valid = false;
            }
        }

        if (!valid && this.props.withNumberVerificationMessage) {
            this.onSetInputError(name, numberType);
        }

        return valid;
    };

    onSetInputError = (name, errorType) => {
        this.onRemoveInputError(name);

        const element = document.querySelector(`[name='${name}']`);
        if (element) {
            element.parentNode.classList.add(styles.required);

            this.setState({typeError: {name, type: errorType, element}});

            this.timerId = setTimeout(() => {
                element.parentNode.classList.remove(styles.required);
                this.setState({typeError: null});
            }, 1700);
        }
    };

    onRemoveInputError = (name) => {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }

        if (this.state.typeError && this.state.typeError.name === name) {
            this.state.typeError.element.parentNode.classList.remove(styles.required);
            this.setState({typeError: null});
        }
    };

    componentWillUnmount() {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }
    }

    render() {
        const {
            staticData, id, name, value,
            label, tooltip, props, correct, onRemoveInputField,
        } = this.props;
        let {type} = this.props;
        const {typeError, isVisiblePassword, pasteValue} = this.state;
        const {input_type_message, input_type} = staticData;

        let integerNumber, floatNumber, isRequired, isReadOnly, isDisabled,
            withRemoveFieldButton, withDifferentBorder, withRedBorder;

        if (props) {
            integerNumber = props.includes('int');
            floatNumber = props.includes('float')
            isRequired = props.includes('required');
            isReadOnly = props.includes('readonly');
            isDisabled = props.includes('disabled');
            withRemoveFieldButton = props.includes('with-remove-field-button');
            withDifferentBorder = props.includes('with-different-border');
            withRedBorder = props.includes('with-red-border');
        }

        const numberInput = integerNumber || floatNumber;
        const numberType = numberInput ? integerNumber ? 'int' : 'float' : null;

        /* in this case this.props.type = 'password' */
        if (isVisiblePassword) {
            type = 'text';
        }

        const cx = classnames.bind(styles);
        const containerClasses = cx('container', {
                'with-hover': withRemoveFieldButton,
                'with-different-border': withDifferentBorder,
                'with-red-border': withRedBorder,
            },
        );
        const labelClasses = cx('label', {'with-tooltip': tooltip});
        const eyeIconClasses = cx('eye-icon', {'active': isVisiblePassword});
        const inputClasses = cx('input', {
            'input-number': numberInput,
            'input-password': type === 'password',
            'input-password-confirmation': type === 'password' && name.includes('password_confirmation'),
        });

        let inputProps = {
            id,
            name,
            type,
            className: inputClasses,
            readOnly: isReadOnly || false,
            onFocus: this.onInputFocus,
        };

        if (!numberInput) {
            inputProps = {
                ...inputProps,
                ref: this.inputRef,
                value,
                onChange: this.onInputChange,
            };

        } else {
            inputProps = {
                ...inputProps,
                getInputRef: this.inputRef,
                numberType,
                pasteValue,
                customInput: Input,
                value: value.formattedValue ? value.formattedValue : value,
                valueIsNumericString: typeof value === 'string',
                allowNegative: false,
                allowLeadingZeros: true,
                onPasteCaptureCallback: () => this.setState({pasteValue: false}),
                onPasteCapture: () => numberInput && this.setState({pasteValue: true}),
                isAllowed: (values) => this.onValueCheck(values, numberType, name),
                onValueChange: (values) => {
                    return this.onInputChange({
                        target: {
                            name,
                            value: values.formattedValue !== '' ? values : '',
                        },
                    }, numberType);
                },
            };
        }

        return (
            <div className={containerClasses}>
                {numberInput
                    ? <CustomNumericInput {...inputProps}/>
                    : <Input {...inputProps}/>
                }
                <span ref={this.labelRef} className={labelClasses}>
                    {label}
                    {isRequired && <span className={styles['required-icon']}>*</span>}
                </span>
                {tooltip && <TooltipCreator tooltip={tooltip}
                                            style={{left: `${this.state.leftTooltipPosition}px`}}
                />}
                {(isReadOnly || isDisabled) && <div className={styles['read-only']}/>}
                {withRemoveFieldButton && <div className={styles['remove-input-field']} onClick={onRemoveInputField}/>}
                {typeError && (
                    <div className={styles['error-tooltip']}>
                        {`${input_type_message} ${input_type[typeError.type]}`}
                    </div>
                )}
                {(type === 'password' || isVisiblePassword) &&
                    <div className={eyeIconClasses} onClick={this.onTogglePasswordVisibility}/>}
                {(type === 'password' || isVisiblePassword) && correct !== null && correct !== undefined && (
                    <div className={cx('password-confirmation', {
                        'correct': correct,
                        'not-correct': !correct,
                    })}/>
                )}
            </div>
        );
    }

    static defaultProps = {
        value: '',
        withNumberVerificationMessage: true,
        onInputChange: () => {
        },
        onInputFocus: () => {
        },
    };

    static propTypes = {
        staticData: PropTypes.object,
        id: PropTypes.any,
        type: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.any,
        label: PropTypes.string,
        tooltip: PropTypes.object,
        props: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.array,
            PropTypes.arrayOf(PropTypes.string),
        ]),
        correct: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
        withNumberVerificationMessage: PropTypes.bool,
        onInputChange: PropTypes.func,
        onInputFocus: PropTypes.func,
        onRemoveInputField: PropTypes.func,
    };
}

export default withDataService('input_field')(InputField);